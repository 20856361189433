/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js
 * - /npm/loading-attribute-polyfill@2.1.1/dist/loading-attribute-polyfill.umd.min.js
 * - /npm/glightbox@3.3.0/dist/js/glightbox.min.js
 * - /npm/clipboard@2.0.11/dist/clipboard.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
